.thank-you {
  background-color: black;
  width: 100%;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  opacity: 0;
  overflow: hidden;
  max-width: 95%;
  max-height: 95%;
  max-width: 320px;
  padding: 70px 80px;
  -webkit-box-shadow: -1px -1px 10px -3px #000000;
  box-shadow: -1px -1px 10px -3px #000000;
  transition: height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.thank-you--show {
  height: auto;
  opacity: 1;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}

.backdrop {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
}

.backdrop--show {
  width: 100%;
  height: 100vh;
  opacity: 1;
}

.thank-you__text {
  font-size: 18px;
  line-height: 20px;
  padding: 4px;
}

@media screen and (min-width: 640px) {
  .thank-you__text {
    font-size: 24px;
    line-height: 28px;
  }

  .thank-you--show {
    top: 30%;
  }
}
