.banner__heading {
  font-size: 36px;
  line-height: 48px;
}

.banner__sub-heading {
  font-size: 20px;
  line-height: 22px;
}

.banner__img {
  max-width: 280px;
}

@media screen and (min-width: 480px) {
  .banner__img {
    max-width: 480px;
  }
}

@media screen and (min-width: 640px) {
  .banner__heading {
    font-size: 48px;
    line-height: 52px;
  }
}

@media screen and (min-width: 840px) {
  .banner__heading {
    font-size: 48px;
    line-height: 52px;
  }

  .banner__img {
    max-width: 380px;
    width: 90%;
  }

  .banner__img-container {
    min-height: 432px;
    min-width: 380px;
  }
}

@media screen and (min-width: 1024px) {
  .banner__heading {
    font-size: 52px;
    line-height: 60px;
  }

  .banner__sub-heading {
    font-size: 24px;
    line-height: 28px;
  }

  .banner__img {
    width: auto;
  }
}
