.form__description {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  max-width: 240px;
  margin-bottom: 48px;
}

.form__description--large {
  max-width: 320px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 48px;
}

.app-form {
  max-width: 480px;
  padding: 8px 16px;
}

.app-select div {
  border-radius: 0px;
}

.app-select input {
  min-height: 36px;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid black;
}

.app-select__placeholder {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: rgba(106, 116, 130, 0.7) !important;
  text-align: center;
  transform: translate(-50%, -50%) !important;
  left: 60%;
  width: 90%;
}

.app-select__single-value {
  text-align: center;
  transform: translate(-50%, -50%) !important;
  left: 55%;
  width: 90%;
}

.app-select__value-container {
  position: relative;
}

.app-select__menu {
  text-align: center;
}

.app-select__value-container,
.app-select__indicators {
  padding-left: 16px !important;
  height: 42px;
}

.app-select--error {
  border: 1px solid #d32f2f;
}

.form-button {
  max-width: 200px;
}

@media screen and (min-width: 640px) {
  .app-select {
    min-height: 42px;
  }

  .form-button {
    max-width: 240px;
  }
}

@media screen and (min-width: 1024px) {
  .app-select {
    min-height: 48px;
  }

  .app-select__value-container,
  .app-select__indicators {
    padding-left: 16px !important;
    height: 44px;
  }

  .form-button {
    max-width: 320px;
  }
}

.close-modal {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 50;
}

form input,
form textarea {
  text-align: center;
}
