.get-in-touch__title {
  font-size: 18px;
  line-height: 20px;
  padding: 4px;
}

@media screen and (min-width: 640px) {
  .get-in-touch__title {
    font-size: 20px;
    line-height: 22px;
  }
}

@media screen and (min-width: 840px) {
  .get-in-touch__title {
    font-size: 22px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1024px) {
  .get-in-touch__title {
    font-size: 24px;
    line-height: 28px;
  }
}
