#loading {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media screen and (min-width: 640px) {
  #loading {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1024px) {
  #loading {
    width: 21px;
    height: 21px;
  }
}
