.app-logo {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.6em;
  margin-right: -0.6em;
  padding: 4px;
}

@media screen and (min-width: 640px) {
  .app-logo {
    font-size: 20px;
    line-height: 22px;
  }
}

@media screen and (min-width: 840px) {
  .app-logo {
    font-size: 22px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1024px) {
  .app-logo {
    font-size: 24px;
    line-height: 28px;
  }
}
