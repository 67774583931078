*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Common Styles */
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.section-title {
  font-size: 32px;
  line-height: 36px;
}

@media screen and (min-width: 640px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .section-title {
    font-size: 44px;
    line-height: 52px;
  }
}
