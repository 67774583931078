.app-textarea {
  color: var(--color-pale-sky);
  font-weight: 500;
  min-height: 36px;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  padding: 12px 16px;
  border: 2px solid black;
}

.app-textarea::placeholder {
  font-size: 14px;
}

.app-textarea--error {
  border: 2px solid #d32f2f;
}

@media screen and (min-width: 640px) {
  .app-textarea {
    min-height: 42px;
  }
}

@media screen and (min-width: 1024px) {
  .app-textarea {
    min-height: 48px;
  }
}
