.btn {
  color: black;
  border-radius: 4px;
  transition: all 0.3s;
  padding: 4px 26px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
}

.btn:focus {
  outline-color: black;
}

.btn:disabled {
  opacity: 0.9;
  pointer-events: none;
}

.btn-rounded {
  border-radius: 32px;
}

/* Color classes */
.btn-default {
  background-color: var(--color-brown);
  color: var(--color-pampas);
}

.btn-default:hover {
  background-color: #734f34;
}

.btn-primary {
  background-color: var(--color-mandys-pink);
  color: black;
}

.btn-primary:hover {
  background-color: #cfa68a;
}

.btn-secondary {
  background-color: var(--color-silver);
  color: black;
}

.btn-secondary:hover {
  background-color: #949292;
}

.btn-info {
  background-color: var(--color-mandys-pink);
  color: black;
}

.btn-info:hover {
  background-color: #edba98;
}

@media screen and (min-width: 640px) {
  .btn {
    padding: 6px 32px;
    font-size: 16px;
    line-height: 20px;
  }

  .btn-rounded {
    border-radius: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .btn {
    padding: 8px 36px;
    font-size: 18px;
    line-height: 21px;
  }
}
