.products {
  display: grid;
  column-gap: 20px;
  row-gap: 16px;
  grid-template-columns: 1fr;
}

.product {
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 180px;
}

.product__title {
  width: 80%;
}

@media screen and (min-width: 525px) {
  .products {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
}

@media screen and (min-width: 840px) {
  .products {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
  }
}

@media screen and (min-width: 1100px) {
  .products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 32px;
    row-gap: 32px;
  }
}
